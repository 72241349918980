import "./index.css"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals"
import { AppRouter } from "./routes"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { configureStore } from "stores/configureStore"
import IconCloseNotification from "assets/images/icons/icon-close-notification.svg"
import { ModalController } from "controllers/ModalController"
import BackdropCustomize from "components/BackdropCustomize"

const CloseButton = ({ closeToast }) => (
  <img src={IconCloseNotification} alt="icon" onClick={closeToast} />
)
//
ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore}>
      <AppRouter />
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        bodyClassName="toastBody"
        pauseOnFocusLoss
        draggable
        pauseOnHover
        position="bottom-right"
        closeButton={CloseButton}
      />
      <ModalController />
      <BackdropCustomize />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
