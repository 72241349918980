import { createBrowserHistory } from "history"
export const history = createBrowserHistory()

export const pushTo = (path: string, params?: {}, props?: {}, query?: {}) => {
  let pathWithParams = path
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      pathWithParams = pathWithParams.replace(":" + key, value as string)
    }
  }
  let queryString = "?"
  if (query) {
    for (const [key, value] of Object.entries(query)) {
      queryString = `${queryString}${key}=${value}&`
    }
    queryString = queryString.slice(0, -1)
  }
  history.push({
    pathname: pathWithParams,
    state: props,
    search: query ? queryString : undefined,
  })
}

export const goBack = () => history.goBack()

export const getLastPartOfPath = (path: string) => {
  const urlItems = path.split("/")
  return urlItems[urlItems.length - 1]
    ? urlItems[urlItems.length - 1]
    : urlItems[urlItems.length - 2]
}

export const useQuery = () => {
  return new URLSearchParams(window.location.search)
}

export const generateQueryString = (query?: object) => {
  let queryString = "?"
  if (query) {
    for (const [key, value] of Object.entries(query)) {
      queryString = `${queryString}${key}=${value}&`
    }
  }
  return queryString.slice(0, -1)
}
